exports.components = {
  "component---src-components-page-js-content-file-path-content-pages-accessibility-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/new-work/content/pages/accessibility.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-pages-accessibility-mdx" */),
  "component---src-components-page-js-content-file-path-content-pages-legal-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/new-work/content/pages/legal.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-pages-legal-mdx" */),
  "component---src-components-page-js-content-file-path-content-pages-privacy-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/new-work/content/pages/privacy.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-pages-privacy-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-0-intro-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/0_intro.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-0-intro-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-1-agiles-playbook-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/1_agiles-playbook.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-1-agiles-playbook-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-10-new-work-science-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/10_new_work_science.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-10-new-work-science-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-11-kap-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/11_kap.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-11-kap-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-12-projektmanagement-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/12_projektmanagement.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-12-projektmanagement-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-13-agile-manifesto-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/13_agile-manifesto.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-13-agile-manifesto-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-14-teamgeist-trotz-distanz-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/14_teamgeist-trotz-distanz.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-14-teamgeist-trotz-distanz-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-15-vertrauen-digitale-fuehrung-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/15_vertrauen-digitale-fuehrung.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-15-vertrauen-digitale-fuehrung-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-16-augen-der-eule-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/16_augen-der-eule.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-16-augen-der-eule-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-17-raumbuchungssysteme-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/17_raumbuchungssysteme.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-17-raumbuchungssysteme-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-18-buchvorstellungen-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/18_buchvorstellungen.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-18-buchvorstellungen-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-19-konferenzraumplanung-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/19_konferenzraumplanung.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-19-konferenzraumplanung-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-2-kollaboration-digital-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/2_kollaboration-digital.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-2-kollaboration-digital-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-20-pierjo-kiefer-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/20_pierjo-kiefer.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-20-pierjo-kiefer-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-21-gei-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/21_gei.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-21-gei-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-22-onbaording-1-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/22_onbaording_1.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-22-onbaording-1-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-23-onbaording-2-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/23_onbaording_2.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-23-onbaording-2-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-24-onbaording-3-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/24_onbaording_3.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-24-onbaording-3-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-25-onbaording-4-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/25_onbaording_4.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-25-onbaording-4-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-26-dos-donts-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/26_dos-donts.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-26-dos-donts-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-27-resilienz-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/27_resilienz.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-27-resilienz-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-28-kulturwandel-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/28_kulturwandel.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-28-kulturwandel-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-29-experimentierraum-0-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/29_experimentierraum-0.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-29-experimentierraum-0-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-3-inner-work-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/3_inner-work.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-3-inner-work-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-30-experimentierraum-1-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/30_experimentierraum-1.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-30-experimentierraum-1-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-31-experimentierraum-2-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/31_experimentierraum-2.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-31-experimentierraum-2-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-32-experimentierraum-3-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/32_experimentierraum-3.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-32-experimentierraum-3-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-33-experimentierraum-4-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/33_experimentierraum-4.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-33-experimentierraum-4-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-34-experimentierraum-5-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/34_experimentierraum-5.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-34-experimentierraum-5-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-35-experimentierraum-6-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/35_experimentierraum-6.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-35-experimentierraum-6-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-36-experimentierraum-7-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/36_experimentierraum-7.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-36-experimentierraum-7-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-37-experimentierraum-8-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/37_experimentierraum-8.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-37-experimentierraum-8-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-38-experimentierraum-9-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/38_experimentierraum-9.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-38-experimentierraum-9-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-39-experimentierraum-10-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/39_experimentierraum-10.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-39-experimentierraum-10-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-4-blick-in-die-zukunft-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/4_blick-in-die-zukunft.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-4-blick-in-die-zukunft-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-40-experimentierraum-11-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/40_experimentierraum-11.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-40-experimentierraum-11-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-5-pflanzen-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/5_pflanzen.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-5-pflanzen-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-6-zbw-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/6_zbw.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-6-zbw-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-7-gesis-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/7_gesis.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-7-gesis-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-8-safe-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/8_safe.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-8-safe-mdx" */),
  "component---src-components-post-js-content-file-path-content-posts-9-nachhaltigkeit-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/new-work/content/posts/9_nachhaltigkeit.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-posts-9-nachhaltigkeit-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-glossar-js": () => import("./../../../src/pages/glossar.js" /* webpackChunkName: "component---src-pages-glossar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

